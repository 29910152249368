import axios from "axios";
import { BASE_URL } from "./baseUrls";

import {
  InterceptorRequestHandler,
  InterceptorResponseErrorHandler,
  InterceptorResponseHandler,
} from "./InterceptorHandler";
import { API_URL } from "./urlMetaData";

// get region value from local storage
const ApiService = axios.create({ baseURL: BASE_URL.API });

// Service Interceptors Configuration
ApiService.interceptors.request.use((req) => InterceptorRequestHandler(req));

ApiService.interceptors.response.use(
  (res) => {
    InterceptorResponseHandler(res);
    return res;
  },
  (error) => {
    InterceptorResponseErrorHandler(error);
    return error;
  }
);

// Authentication
export const ClientLogin = async (payload) => {
  const res = await ApiService.post(API_URL.CLIENT_LOGIN, payload);
  return res.data;
};

export const clientRegistration = async ({ payload }) => {
  const res = await ApiService.post(API_URL.CLIENT_ONBOARD, payload);
  return res.data;
};

export const clientAccVerification = async ({ token }) => {
  const res = await ApiService.post(API_URL.CLIENT_VERIFY, {
    token,
  });
  return res.data;
};

// client handlers
export const fetchClients = async ({ status }) => {
  const res = await ApiService.get(`${API_URL.CLIENTS}?status=${status}`);
  return res.data;
};

export const fetchUsers = async ({ role, status, client = "" }) => {
  const res = await ApiService.get(
    `${API_URL.USERS_LIST}?status=${status}&role=${role}&client=${client}`
  );
  return res.data;
};

export const fetchUserById = async ({ id, populate = "" }) => {
  const res = await ApiService.get(
    `${API_URL.USERS}/${id}?populate=${populate}`
  );
  return res.data;
};

export const fetchClientById = async ({ id }) => {
  const res = await ApiService.get(`${API_URL.CLIENTS}/${id}`);
  return res.data;
};

export const fetchStudents = async ({ client = "", grade = "" }) => {
  const res = await ApiService.get(
    `${API_URL.STUDENTS_LIST}?client=${client}&class=${grade}`
  );
  return res.data;
};

export const fetchSurveyById = async ({ id }) => {
  const res = await ApiService.get(`${API_URL.SURVEYS}/${id}`);
  return res.data;
};

export const fetchSurveys = async ({ grade = "" }) => {
  const res = await ApiService.get(`${API_URL.SURVEYS}?grade=${grade}`);
  return res.data;
};

export const fetchSurveyResponses = async (payload) => {
  const res = await ApiService.post(
    `${API_URL.SURVEYS_STD_RESPONSES}`,
    payload
  );
  return res.data;
};

export const fetchSurveyResponseResults = async (payload) => {
  const res = await ApiService.post(`${API_URL.SURVEYS_STD_RESULTS}`, payload);
  return res.data;
};

export const UploadSurvey = async (payload) => {
  const res = await ApiService.post(`${API_URL.SURVEYS_UPLOAD}`, payload);
  return res.data;
};

export const DeleteSurvey = async ({ surveyId = "" }) => {
  const res = await ApiService.delete(`${API_URL.SURVEYS_DELETE}/${surveyId}`);
  return res.data;
};

export const UpdateUserStatus = async (payload) => {
  const res = await ApiService.post(`${API_URL.USERS_UPDATE_STATUS}`, payload);
  return res.data;
};

export const UpdateClientStatus = async (payload) => {
  const res = await ApiService.post(`${API_URL.CLIENT_UPDATE_STATUS}`, payload);
  return res.data;
};

export const ResetClientPassword = async ({ email }) => {
  const res = await ApiService.put(`${API_URL.CLIENT_RESET_PASSWORD}`, {
    email,
  });
  return res.data;
};

export const ClientVerification = async (payload) => {
  const res = await ApiService.put(
    `${API_URL.CLIENT_VERIFY_ADMIN}?${payload.unverify ? `unverify=true` : ""}`,
    { clientId: payload.clientId }
  );
  return res.data;
};

export const UserVerification = async ({ userId = "", unverify = false }) => {
  const res = await ApiService.put(
    `${API_URL.USER_VERIFY_ADMIN}?${unverify ? `unverify=true` : ""}`,
    { userId }
  );
  return res.data;
};

export const fetchUserStats = async ({ role = "" }) => {
  const res = await ApiService.get(`${API_URL.USERS_STATS}?role=${role}`);
  return res.data;
};

export const fetchClientStats = async () => {
  const res = await ApiService.get(`${API_URL.CLIENTS_STATS}`);
  return res.data;
};
export default ApiService;
